// import mixpanel from 'mixpanel-browser';

// Adding mixpanel
// const MIXPANEL_TOKEN = process.env.NEXT_PUBLIC_MIXPANEL_TOKEN;
 
// export const initMixpanel = () => {
//   if (!MIXPANEL_TOKEN) {
//     console.warn('Mixpanel token is missing! Check your .env file.');
//     return;
//   }
 
//   mixpanel.init(MIXPANEL_TOKEN, { autocapture: false });
// }

export const trackAnalyticsEvent = (name, metadata) => {
  if (window.sa_event) {
    window.sa_event(name, {
      ...metadata,
      path: window.location.pathname,
    });
  }
  if (window.dataLayer) {
    window.dataLayer.push({
      event: name,
      ...metadata,
      path: window.location.pathname,
    });
  }
};

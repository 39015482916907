// Auto generated by npm run util:updateLogos at Tue Apr 01 2025 18:37:44 GMT+0100 (Irish Standard Time)

  export const logoMap = {
    zapier: { id: "zapier", width: 500, height: 136 },
  vagrant: { id: "vagrant", width: 538, height: 155 },
  unity: { id: "unity", width: 264, height: 97 },
  travis: { id: "travis", width: 795, height: 789 },
  terraform: { id: "terraform", width: 55, height: 61 },
  teams: { id: "teams", width: 2229, height: 2074 },
  swift: { id: "swift", width: 32, height: 32 },
  slack: { id: "slack", width: 48, height: 48 },
  semaphore: { id: "semaphore", width: 163.98, height: 22.37 },
  sbt: { id: "sbt", width: 257.333, height: 147.833 },
  rust: { id: "rust", width: 58, height: 57 },
  rubygems: { id: "rubygems", width: 58, height: 60 },
  ruby: { id: "ruby", width: 256, height: 293 },
  roadie: { id: "roadie", width: 27, height: 31 },
  redhat: { id: "redhat", width: 75, height: 56 },
  python: { id: "python", width: 58, height: 58 },
  puppet: { id: "puppet", width: 850.394, height: 300.186 },
  powershell: { id: "powershell", width: 802, height: 603 },
  pingfederate: { id: "pingfederate", width: 126, height: 22 },
  php: { id: "php", width: 98, height: 48 },
  onelogin: { id: "onelogin", width: 142.8, height: 35.2 },
  okta: { id: "okta", width: 109.21, height: 36 },
  octopus: { id: "octopus", width: 140, height: 140 },
  nuget: { id: "nuget", width: 512, height: 512 },
  npm: { id: "npm", width: 78, height: 25 },
  myob: { id: "myob", width: 284, height: 117 },
  microsoft: { id: "microsoft", width: 337.6, height: 72 },
  maven: { id: "maven", width: 105, height: 25 },
  lua: { id: "lua", width: 949, height: 949 },
  kong: { id: "kong", width: 153, height: 137 },
  jumpcloud: { id: "jumpcloud", width: 420, height: 95 },
  jenkins: { id: "jenkins", width: 180, height: 180 },
  isc: { id: "isc", width: 200.05, height: 125 },
  hex: { id: "hex", width: 200, height: 174 },
  helm: { id: "helm", width: 77, height: 90 },
  harness: { id: "harness", width: 931, height: 206 },
  ha: { id: "ha", width: 204, height: 50 },
  gradle: { id: "gradle", width: 54, height: 41 },
  google: { id: "google", width: 210, height: 210 },
  go: { id: "go", width: 210, height: 80 },
  gitlab: { id: "gitlab", width: 194, height: 186 },
  github: { id: "github", width: 55, height: 53 },
  eclipse: { id: "eclipse", width: 470, height: 111 },
  drone: { id: "drone", width: 200, height: 200 },
  docker: { id: "docker", width: 64, height: 46 },
  dependabot: { id: "dependabot", width: 24, height: 24 },
  debian: { id: "debian", width: 45, height: 55 },
  datadog: { id: "datadog", width: 800.5, height: 907.77 },
  cran: { id: "cran", width: 87, height: 65 },
  conda: { id: "conda", width: 497, height: 103 },
  conan: { id: "conan", width: 241, height: 256 },
  composer: { id: "composer", width: 1888, height: 2495 },
  codefresh: { id: "codefresh", width: 24, height: 24 },
  cocoapods: { id: "cocoapods", width: 55, height: 37 },
  circleci: { id: "circleci", width: 178, height: 40 },
  chocolatey: { id: "chocolatey", width: 154, height: 138 },
  chef: { id: "chef", width: 363.95, height: 60.47 },
  chainguard: { id: "chainguard", width: 24, height: 24 },
  buildkite: { id: "buildkite", width: 500, height: 334 },
  bitbucket: { id: "bitbucket", width: 32, height: 32 },
  binary: { id: "binary", width: 46, height: 38 },
  azuredevops: { id: "azuredevops", width: 53, height: 53 },
  awscode: { id: "awscode", width: 100, height: 100 },
  argo: { id: "argo", width: 24, height: 24 },
  ansible: { id: "ansible", width: 69, height: 69 },
  alpine: { id: "alpine", width: 665, height: 162 }
  };

  export const spritePath = '/logos.svg';

  export default function getThirdPartyLogo(slug) {
    return logoMap[slug] || null;
  }
  